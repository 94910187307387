import React from "react";
import { useTranslation } from "react-i18next";

const Advantage = () => {
  const { t } = useTranslation();
  const basePath = "advantage.";

  return (
    <div className="advantage">
      <section>
        <h1>{t(`${basePath}title`)}</h1>
        <p>{t(`${basePath}desc`)}</p>

        <div className="priority">
          <div className="cell">
            <div className="img">
              <img src={require("../../assets/img/icon/img-1.jpg")} alt="" />
            </div>
            <div className="textBlock">
              <div className="title">{t(`${basePath}item1.title`)}</div>
              <div className="text">{t(`${basePath}item1.desc`)}</div>
            </div>
          </div>
          <div className="cell">
            <div className="img">
              <img src={require("../../assets/img/icon/img-2.jpg")} alt="" />
            </div>
            <div className="textBlock">
              <div className="title">{t(`${basePath}item2.title`)}</div>
              <div className="text">{t(`${basePath}item2.desc`)}</div>
            </div>
          </div>
          <div className="cell">
            <div className="img">
              <img src={require("../../assets/img/icon/img-3.jpg")} alt="" />
            </div>
            <div className="textBlock">
              <div className="title">{t(`${basePath}item3.title`)}</div>
              <div className="text">{t(`${basePath}item3.desc`)}</div>
            </div>
          </div>
          <div className="cell">
            <div className="img">
              <img src={require("../../assets/img/icon/img-4.jpg")} alt="" />
            </div>
            <div className="textBlock">
              <div className="title">{t(`${basePath}item4.title`)}</div>
              <div className="text">{t(`${basePath}item4.desc`)}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Advantage;
