import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation();
  const basePath = "partners.";

  return (
    <main>
      <NavBar className={"d-none"} />
      <div className="order v2">
        <section>
          <div className="main v2">
            <h1 className="title">{t(`${basePath}title`)}</h1>
            <div className="box">
              <p className="m-1">{t(`${basePath}desc1`)}</p>
              <ul>
                <li>{t(`${basePath}list1.item1`)}</li>
                <li>{t(`${basePath}list1.item2`)}</li>
                <li>{t(`${basePath}list1.item3`)}</li>
              </ul>
              <p className="m-1">{t(`${basePath}desc2`)}</p>
              <ul>
                <li>{t(`${basePath}list2.item1`)}</li>
                <li>{t(`${basePath}list2.item2`)}</li>
                <li>{t(`${basePath}list2.item3`)}</li>
                <li>{t(`${basePath}list2.item4`)}</li>
                <li>{t(`${basePath}list2.item5`)}</li>
              </ul>
              <p className="m-1">{t(`${basePath}desc3`)}</p>
              <p className="m-1">{t(`${basePath}desc4`)}</p>
              <p className="m-1">{t(`${basePath}desc5`)}</p>
              <p className="m-1">{t(`${basePath}desc6`)}</p>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </main>
  );
};

export default Partners;
