import React from "react";
import { Header } from "antd/es/layout/layout";
import { Dropdown } from "antd";
import { languages } from "../i18n";
import { useLanguage } from "../contexts/LanguageContext";
import { CRYPTOCURRENCY_TO } from "../constants/CryptocurrencyArray";
import { useTranslation } from "react-i18next";

function NavBar({ className }) {
  const { t } = useTranslation();
  const basePath = "navBar.";

  const { changeLanguage } = useLanguage();
  const activeLang = localStorage.getItem("i18nextLng");

  const languagesArr = languages.map((item, index) => ({
    key: index,
    label: <span className="item-lang">{t(`${basePath}lang.${item}`)}</span>,
  }));

  const onLangChange = ({ key }) => {
    const lang = languages[+key];
    localStorage.setItem("i18nextLng", lang);
    changeLanguage(lang);
  };

  const getActualSymbol = (symbol) => {
    if (symbol && symbol.startsWith("USDT")) {
      return "USDT";
    }
    return symbol || "";
  };

  return (
    <>
      <div className="top-bar">
        <section>{t(`${basePath}topBar`)}</section>
      </div>

      <Header className={className}>
        <section>
          <div className="head">
            <div className="leftSide">
              <div className="leftSide__block">
                <div className="logo">
                  <a href="/">
                    <img src={require("../assets/img/logo.png")} alt="" />
                  </a>
                </div>
                <div className="balance">
                  <span>
                    <s>{t(`${basePath}balances.title`)}</s>
                    <s>{t(`${basePath}balances.desc`)}</s>
                  </span>
                  <ul>
                    {CRYPTOCURRENCY_TO.map((item, i) => (
                      <li key={i}>
                        <s>{item.name}</s> {item.reserve}{" "}
                        {getActualSymbol(item.symbol)}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="arrow">
                  <img src={require("../assets/img/arrow.png")} alt="" />
                </div>
              </div>
            </div>
            <div className="rightSide">
              <div className="rightSide__top">
                <div>
                  <span>{t(`${basePath}time.title`)} </span>
                  <span>{t(`${basePath}time.desc`)}</span>
                </div>
                <div>
                  <div className="auth">
                    <button type="button" className="reg">
                      {t(`${basePath}btnReg`)}
                    </button>
                    <button type="button" className="login">
                      {t(`${basePath}btnLog`)}
                    </button>
                  </div>
                  <Dropdown
                    className="lang"
                    menu={{ items: languagesArr, onClick: onLangChange }}
                    trigger={["click"]}
                  >
                    <button type="button" className={`dropdown-btn`}>
                      Select language: {t(`${basePath}lang.${activeLang}`)}
                    </button>
                  </Dropdown>
                </div>
              </div>

              <div className="rightSide__menu">
                <ul>
                  <li>
                    <a href="/" className="active">
                      {t(`${basePath}nav.item1`)}
                    </a>
                  </li>
                  <li>
                    <a href="/rules/">{t(`${basePath}nav.item2`)}</a>
                  </li>
                  <li>
                    <a href="/aml/">{t(`${basePath}nav.item3`)}</a>
                  </li>
                  <li>
                    <a href="/partners/">{t(`${basePath}nav.item4`)}</a>
                  </li>
                  <li>
                    <a href="/contact/">{t(`${basePath}nav.item5`)}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="head-mob">
            <div className="head-mob__top">
              <div className="menu">
                <button className="menuIco"></button>
              </div>
              <div className="logo">
                <a href="/">
                  <img
                    src={require("../assets/img/logo.png")}
                    width="220px"
                    alt=""
                  />
                </a>
              </div>
              <div className="authBlock">
                <button className="authIco"></button>
              </div>
            </div>
            <div className="head-mob__lang">
              <Dropdown
                className="lang"
                menu={{ items: languagesArr, onClick: onLangChange }}
                trigger={["click"]}
              >
                <button type="button" className={`dropdown-btn`}>
                  Select language: {t(`${basePath}lang.${activeLang}`)}
                </button>
              </Dropdown>
            </div>
          </div>
        </section>
      </Header>
    </>
  );
}

export default NavBar;
