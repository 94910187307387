export const TRANSLATIONS_EN = {
  navBar: {
    lang: {
      "ru-RU": "Russian",
      "en-US": "English",
    },

    topBar: 'Attention! Our website is located only at n-change.net We do not provide details for exchange outside our website, be careful!',
    balances: {
      title: 'PAYMENT SYSTEM',
      desc: 'RESERVE',
    },
    time: {
      title: 'Operating time:',
      desc: 'Around the clock',
    },
    btnReg: 'SIGN UP',
    btnLog: 'LOG IN',
    nav: {
      item1: 'Exchange',
      item2: 'Terms of service',
      item3: 'AML/KYC',
      item4: 'For Partners',
      item5: 'Contacts',
    }
  },

  exchange: {
    title: {
      main: 'Exchange',
      and: 'to',
    },
    aml: "AML company policy",
    form: {
      columnInput: {
        title: "YOU GIVE",
        right: 'Select exchange direction',
      },
      columnOutput: {
        title: "OU TAKE",
        right: 'Reserve ',
        desc: "Курс фиксируется после получения необходимых подтверждений и зависит от фактической средней стоимости открытия ордера по маркету",
      },
      rate: 'EXCHANGE RATE:',
      email: {
        label: 'Enter your email',
        error: "Enter the correct Email",
      },
      wallet: {
        label: "wallet",
        error: "Incorrect wallet number",
      },
      captcha: "Введите код",
      checkbox: {
        first: {
          title: 'I AGREE WITH',
          rules: "TERMS AND CONDITIONS OF SERVICE",
          error: "To create an application, you need to accept the terms and conditions of the service",
        },
        second: {
          title: 'I AGREE WITH',
          rules: "THE AML/KYC POLICY",
          error: "To create an application, you need to accept the AML / KYC policy of the service",
        }
      },
      btn: "EXCHANGE",
      info: {
        desc1: "Сервисы электронной почты @protonmail.com, @proton.me, @startmail.com, @tutanota.com и др. - заблокированы в России по решению Роскомнадзора. Письма с таких сервисов не поступают. Пожалуйста, используйте иные почтовые сервисы.",
        desc2: 'All transactions pass AML verification. High-risk transactions are processed according to sub-paragraphs',
        desc3: 'The application is processed manually, the rate is floating. The rate is fixed at the moment of receiving 2 network confirmations and coin purchases on the market. The amount to be received will be recalculated at the time of opening the order on the market at the average price per conversion. The maximum amount of change in the amount received is not more than 5%. For the calculation, we use the Binance exchange rate. It can take up to 30 minutes for a transaction to go online.',
      }
    },

    order: {
      title: "Complete the exchange following the instructions",
      desc: "Dear Customer! We inform you that the time for payment of the application is limited. The application must be paid within 18 minutes.",
      step: {
        amount: "Amount:",
        wallet: "Wallet:",
        purpose: "Purpose:",
        purposeName: "Personal transfer",
        copy: 'Copy',
        step1: "Check your details and exchange amount",
        step2: 'Make a transfer using the requisites:',
        step3: 'After payment, be sure to click the "I paid" button for the application to be created',
        btnPay: 'I paid',
        btnCancel: 'Cancel',
      },
    },

    finish: {
      title: "Application # 110802 successfully created and accepted for processing!",
      desc1: 'Within 20 minutes after the receipt of your money to our account, the application will be processed and the funds will be transferred to the account you specified.',
      desc2: 'You can track its status in your personal account.',
      desc3: 'Please make sure that you not only clicked on the "I paid" button, but actually transferred funds to us.',
      desc4: "For any questions or in case of a delay in the exchange, please contact technical support.",
      desc5: "Respectfully, administration site!",
      btn: "Create another application",
    }
  },

  advantage: {
    title: 'Electronic money exchanger',
    desc: 'You can make an exchange in 15 minutes in our exchanger with Bitcoin, Perfect Money, Qiwi, Yandex.Money, Payeer, AdvCash and many other currencies. You can buy funds from banks: Sberbank, Alfa Bank, VTB24, Russian Standard, Tinkoff, Avangard, Gazprombank.',
    item1: {
      title: "Profitable exchange 24/7",
      desc: "We have been qualitatively changing currency online for the past 3 years.",
    },
    item2: {
      title: "100% exchange guarantee",
      desc: "Numerous positive reviews confirm the high quality of our work.",
    },
    item3: {
      title: "Fast exchange up to 7 min.",
      desc: "We are loved not only for reliability and the best rate, but also for a quick exchange.",
    },
    item4: {
      title: "Economical exchange",
      desc: "We always have loyal courses. We are always open to cooperation, please contact us.",
    },
  },

  rules: {
    title: "Terms of service",

    item1: {
      title: '1. The parties to the agreement.',
      desc1: 'The contract is concluded between the Internet service for the exchange of title signs, hereinafter the Contractor, on the one hand, and the Customer, represented by the one who used the services of the Contractor, on the other hand.',
    },
    item2: {
      title: "2. List of terms.",
      desc1: "2.1. Exchange of title signs is an automated Internet service product, which is provided by the Contractor on the basis of these rules.",
      desc2: "2.2. The Customer is an individual who agrees to the terms of the Contractor and this agreement, to which he joins.",
      desc3: "2.3. The title sign is a conventional unit of a particular payment system, which corresponds to the calculations of electronic systems and indicates the scope of rights corresponding to the contract of the electronic payment system and its Customer.",
      desc4: "2.4. Application — information transmitted by the Customer for the use of the Contractor's funds in electronic form and indicating that he accepts the terms of use of the service, which are offered by the Contractor in this application.",
      desc5: "2.5. Payment/Operation - transfer of electronic currency from the payer to the recipient and back.",
      desc6: "2.6. Service services — input and output of electronic currency, and other services, information about which is posted on the Service's website.",
    },
    item3: {
      title: "3. Terms of the agreement.",
      desc1: "These rules are considered to be organized due to the terms of the public offer, which is formed during the submission of the application by the Customer and is one of the main components of this agreement. The public offer refers to the information displayed by the contractor about the conditions for submitting the application. The main component of the public offer is the actions taken at the end of the application by the Customer and indicating his exact intentions to make a deal on the terms proposed by the Contractor before the completion of this application. The time, date, and parameters of the application are created by the Contractor automatically at the end of the formation of this application. The offer must be accepted by the Customer within 30 minutes from the end of the formation of the application. The service contract comes into force from the moment of receipt of the title signs in the full amount specified in the application from the Customer to the details of the Contractor. Transactions with title signs are taken into account according to the rules, regulations and format of electronic systems for calculations. The contract is valid for a period that is established from the moment of filing the application until termination at the initiative of one of the parties.",
    },
    item4: {
      title: "4. The subject of the agreement.",
      desc1: "By using technical methods, the Contractor undertakes to exchange title signs for a commission fee from the Customer, after this person submits an application and does this by selling title signs to persons wishing to purchase them at an amount specified no lower than in the application submitted by the Customer. The Contractor undertakes to transfer funds to the details specified by the Customer. In case of profit arising during the exchange, it remains on the Contractor's account as an additional benefit and a premium for commission services.",
    },
    item5: {
      title: "5. In addition.",
      desc1: "5.1. If the Contractor receives an amount that differs from the amount indicated in the application, the Contractor makes a recalculation that corresponds to the actual receipt of title signs. If this amount exceeds the amount specified in the application by more than 10%, the Contractor terminates the contract unilaterally and all funds are returned to the Customer's details, taking into account the deducted amount for commission expenses during the transfer.",
      desc2: "5.2. If the title signs are not sent by the Contractor to the Customer's specified details within 24 hours, the Customer has the full right to demand termination of the agreement and cancel his application, thereby returning the title signs to his account in full. The application for termination of the agreement and return of the title signs is executed by the Contractor in the event that the funds have not yet been transferred to the specified details of the Customer. In case of cancellation of the contract, the return of the electronic currency is made within 24 hours from the date of receipt of the request for termination of the contract. If delays in the return occurred through no fault of the Contractor, he is not responsible for them.",
      desc3: "5.3. If the title signs are not received from the Customer to the Contractor's account within the specified period, from the moment the Customer submits the application, the agreement between the parties is terminated by the Contractor on the one hand, since the contract does not enter into force. The customer may not be notified about this. If the title signs arrive at the Contractor's details after the specified period, then such funds are transferred back to the Customer's account, and all commission costs associated with the transfer are deducted from these funds.",
      desc4: "5.4. If there is a delay in transferring funds to the details specified by the Customer, due to the fault of the settlement system, the Contractor is not responsible for damage resulting from a long receipt of funds. In this case, the Customer must agree that all claims will be made to the settlement system, and the Contractor provides assistance to the extent possible within the framework of the law.",
      desc5: "5.5. In case of detection of forgery of communication flows or exerting influence in order to worsen the work of the Contractor, namely its program code, the application is suspended, and the transferred funds are recalculated in accordance with the current agreement. If the Customer does not agree with the recalculation, he has every right to terminate the contract and the title signs will be sent to the details specified by the Customer.",
      desc6: "5.6. In case of using the Contractor's services, the Customer fully agrees that the Contractor bears limited liability corresponding to the scope of these rules of the received title signs and does not give additional guarantees to the Customer, and also does not bear additional responsibility to him. Accordingly, the Customer does not bear additional responsibility to the Contractor.",
      desc7: "5.7. The Customer undertakes to comply with the norms corresponding to the legislation, as well as not to forge communication flows and not to create obstacles to the normal operation of the Contractor's program code.",
      desc8: "5.8. The Contractor is not responsible for the damage and consequences of an erroneous transfer of electronic currency in the event that the Customer specified incorrect details when submitting the application.",
      desc9: "5.9. To confirm the successful transaction, the service requires a certain number of transaction confirmations in the cryptocurrency network: Bitcoin - 2 confirmations, Ethereum - 64 confirmations, Litecoin - 4, Ripple - 1, TRON - 1, Dash - 25, Cardano - 30, Solana - 1, NEM - 1",
      desc10: "5.10. If a transfer is received from the Customer with details other than those specified in the application, or made through the cashier, the Contractor has the right to freeze the transfer until the circumstances are clarified and additional verification is provided.",
      desc11: "5.11. The Customer undertakes to successfully complete the transaction within 60 minutes, otherwise the rate may be recalculated at the time of crediting funds (except for the direction of exchanges, where the rates are fixed at the time of the creation of the application).",
      desc12: "5.12. In the event that the User specified invalid, blocked details or refused to make a decision to complete the transaction, the Service has the right to terminate the transaction and, after a written request from the User to the Service's email address, refund the funds minus a penalty of 2%. Important: send an email. Strictly from the mail specified by the Customer in the application. The funds are returned back strictly to the account from which the receipt was received.",
      desc13: "5.13. If the funds have not been credited to the service account within 2 hours, the exchange service has the right to recalculate the amount in the application at the current exchange rate or return the funds to the sender. All commission costs for the refund will be deducted from the refund amount.",
      desc14: "5.14. The Customer undertakes to submit all documents proving his identity in case of suspicion of fraud and money laundering.",
      desc15: "5.15. The Customer undertakes to provide, upon request of the service, additional information confirming the transfer of funds to the service wallet, in particular screenshots or videos confirming the login to the platform from which the transfer was made.",
      desc16: "5.16. As soon as funds are debited from the Payment System from the Service account, the service for transferring funds to the User is considered fully executed. The transaction is recorded in the transaction history of the corresponding Payment System and in the Service client interface.",
      desc17: "5.17. When making an exchange in the direction of banks, the rate is fixed at the time of creating the application and can be changed without prior notification to the client at the time of crediting funds to the account if the current rate deviates by more than 0.5% from the original rate in the application.",
      desc18: "5.18. The User undertakes to indicate the correct payment details. If the User incorrectly specified the sender's payment details in the transaction, the Service has the right to request additional information to the technical support email to identify the transfer.",
      desc19: "5.19. Applications are processed within 15 minutes after funds are credited to the contractor's account. Admission is considered after full confirmation of the transaction.",
      desc20: "5.20. The exchange rate is indicated on the service's website. Exchange rates are constantly updated.",
      desc21: "5.21. The commission for services is included in the exchange rate of each exchange direction separately appearing with banks.",
      desc22: "5.22. The Courses may be changed unilaterally by the Service, which notifies the Service Users by posting up-to-date information on the Service's website.",
      desc23: "5.23. In the exchange directions in which banks do NOT appear, the calculator displays the expected amount to be paid at the time of creating the application.",
      desc24: "5.24. When creating an application in exchange directions in which banks do NOT appear, the exchange is carried out through the cryptocurrency exchange by opening an order on the market.",
      desc25: "5.25. Any completed operation carried out by the Service at the request of the User cannot be canceled by the User after its completion — sending by the Service of funds or digital currencies owed to the User under previously accepted exchange conditions.",
      desc26: "5.26. The Service does not bear any responsibility for losses, lost profits and other costs of the User resulting from erroneous expectations of the User regarding the exchange rate of the Service, profitability of transactions and other subjective factors.",
      desc27: "5.27. If the transaction has not appeared on the network within 10 minutes from the moment of the creation of the application, the exchange service has the right to recalculate the application at the time of crediting to the exchange.(This rule applies to the directions where the courses are fixed at the time of the application creation.).",
      desc28: "5.28. Only those Users who have fully read and agree with the terms and conditions of service provision by the Service can use the services of the Service.",
      desc29: "5.29 When processing the application by the contractor and providing information about the amount received, the customer must confirm the amount within 10 minutes or refuse the exchange. After the specified time, the customer automatically agrees to the amount of receipt. If the customer refuses the exchange and the funds are credited to the account of the exchange service, the refund is made according to the rules of the exchange service",
    },
    item6: {
      title: "6. Warranty period",
      desc1: "Within 24 hours from the moment of execution of the exchange of title signs, the Contractor gives a guarantee for the services provided, unless other terms are agreed.",
    },
    item7: {
      title: "7. Unforeseen circumstances.",
      desc1: "In the event that unforeseen circumstances arise during the processing of the Customer's application that contribute to the Contractor's failure to comply with the terms of the contract, the deadlines for the execution of the application are postponed to the appropriate duration of force majeure. The Contractor is not responsible for overdue obligations.",
    },
    item8: {
      title: "8. The form of the agreement.",
      desc1: "This agreement is accepted by both parties, represented by the Contractor and the Customer, as an agreement of equal legal force, indicated in writing.",
    },
    item10: {
      title: "10. Claims and disputes.",
      desc1: "Claims under this agreement are accepted by the Contractor in the form of an e-mail in which the Customer indicates the essence of the claim. This letter is sent to the details of the Contractor specified on the website.",
    },
    item11: {
      title: "11. Conducting exchange operations.",
      desc1: "11.1. It is strictly prohibited to use the services of the Contractor for illegal transfers and fraudulent activities. At the conclusion of this agreement, the Customer undertakes to comply with these requirements and, in case of fraud, to bear criminal liability established by the legislation at the moment.",
      desc2: "11.2. If it is impossible to fulfill the request automatically, due to circumstances beyond the control of the Contractor, such as lack of communication, lack of funds, or erroneous Customer data, the funds are credited to the account within the next 24 hours or returned to the Customer's details minus commission costs.",
      desc3: "11.3. At the first request, the Contractor has the right to transmit information about the transfer of electronic currency to law enforcement agencies, the administration of settlement systems, as well as to victims of illegal actions, victims of fraud proven by judicial authorities.",
      desc4: "11.4. The Customer undertakes to submit all documents proving his identity in case of suspicion of fraud and money laundering.",
      desc5: "11.5. The Customer undertakes not to interfere with the Contractor's work and not to cause damage to its software and hardware, and the Customer also undertakes to transmit accurate information to ensure that the Contractor fulfills all the terms of the contract.",
    },
    item12: {
      title: "12. Waiver of obligations.",
      desc1: "12.1. The Contractor has the right to refuse to conclude the contract and execute the application, and without explaining the reasons. This clause applies to any client.",
      desc2: "12.2. The Exchange service is not responsible for making an exchange to third parties, if an exchange is detected by third parties, the service has the right to freeze funds until verification is completed.",
    },
    item13: {
      title: "13. Verification on the Site",
      desc1: "13.1. Using the Platform, the Client agrees to the need to pass verification on the first the requirement of the Site.",
      desc2: "13.2. During the verification process, the Platform may require any personal data from the Client data, in any format and volume that the Platform deems necessary for full identification.",
      desc3: "13.3. All the information received will be used to verify the identity of the Client, identify traces money laundering, terrorist financing, fraud and other financial crimes through the functionality of the Site, its resources, applications.",
      desc4: "13.4. The Client agrees to the Site to conduct the necessary research, directly or through third parties persons to verify the identity or protect the Client and/or the Platform from financial crimes, for example, such like a scam. Such third parties may be KYC services and/or other systems, the Client does not restrict the Site in choosing such.",
      desc5: "13.5. Wallet addresses of transactions carried out on the Site undergo automatic AML verification.",
      desc6: "13.6. In case of blocking by payment systems or cryptocurrency exchanges of funds received from the user, the application is frozen until the funds are fully unblocked. The rate will be fixed at the time of funds unblocking and receiving feedback from the client. The client is obliged to provide the service with all the necessary documents to unlock funds.",
      desc7: "13.7. The Platform has the right to request identification of the Client if the Client's wallet address specified when creating the application may have a connection with the following terms: Darknet Marketplace; Darknet Service; Illegal Service; Fraudulent Exchange; Mixing Service; Ransom; Scam; Stolen Coins.",
      desc8: "13.8. Confirmation of communication will require verification by the Client. Only after passing verification, funds sent by the Client will be returned to the sender's details, but minus commission in the amount of 10%.",
      desc9: "13.9. The Service has the right to block funds and not return them to the User until his identity is verified.",
    },
  },

  aml: {
    title: "Regulations for conducting AML checks and KYC policies",

    desc1: 'AML is a set of measures to counter money laundering, terrorist financing and the creation of weapons of mass destruction. This procedure includes the identification, storage and mutual exchange of information about customers, their profits and transactions between financial institutions and government agencies.',
    desc2: "Financial institutions use the AML principle to verify a business that works with cash payments or has assets in cash, holds money in different accounts and in several banks, transfers them abroad, buys futures, options or other instruments for cash settlement, invests in securities through brokers or dealers. In our case, the cryptocurrency appears.",
    desc3: "KYC is an acronym for the English phrase \"know your customer/client\", or \"know your customer\". This is the name of the mandatory verification of the client's personal data by the financial institution. As part of the procedure, documents confirming the identity of the client are used, for example, a valid identity card, a utility bill indicating the home address, an insurance number, etc. As a rule, customers need to provide information for KYC during the account registration process.",
    desc4: "All incoming cryptocurrency transactions are checked by a specialized service for illegal actions that can be qualified as laundering or an attempt to launder illegally obtained digital assets or funds have an openly criminal origin. In case of detection of \"high risk\" tags, the transaction will be frozen and processed within the framework of the AML regulations.",

    desc5: "1. High-risk transactions include funds with tags:",
    desc6: '1. In the presence of one of the presented labels, the exchange is suspended until the situation is resolved. The Service has the right to require verification of the User\'s identity and request all necessary documents to confirm the origin of funds. For verification, you need to provide:',
    desc7: '2. Photo of the main page of the passport, the page with the specified address of residence. A selfie with a passport (photo on the background of our website). To confirm your address, you must provide a payment list that is no more than 3 months old. (For example, payment for housing and communal services/The Internet/Bank statement) The payment document must be in your name and match the place of registration.',
    desc8: '3. After verification, you will receive a refund minus 2% of the amount received.',
    desc9: '4. If you refuse to undergo verification, then you need to notify us in writing by e-mail support@n-change.net the refusal to pass verification and the refund will be made minus 5% of the amount received.',
    desc10: '5. In case of blocking by payment systems or crypto currency exchanges of funds received from the user, the refund will be made only after the funds are fully unblocked, the refund will be made minus 10% of the amount received.',
    desc11: '6. Important: Send an email. Strictly from the mail specified by the Customer in the application.',
    desc12: '7. The Client is obliged to provide the service with all necessary documents to unlock funds.',
    desc13: '8. The refund fee is charged in connection with the costs of checking AML and other expenses related to funds related to illegal activities, as well as financial losses of the exchange service due to the blocking of reserves.',
    desc14: '9.The Service reserves the right to monitor the entire transaction chain in order to identify suspicious transactions;',
    desc15: "10. The Service reserves the right to refuse to provide the service to the Client if the Service has reasonable suspicions about the legality of the origin of digital assets and to withhold funds in special accounts of the Service;",
    desc16: "11. The Service reserves the right to refuse to provide the service to the Client if the Service has reasonable suspicions about the legality of the origin of digital assets and to withhold funds in special accounts of the Service if it is impossible to track the entire chain of movement of digital assets from the moment of their appearance.",
    desc17: "12. The refund, subject to approval by the Service, will be processed by the Service within 7 (seven) calendar days, starting from the date when the User was notified with the decision of the Service regarding his request for a refund. The refund is made to the details from which the payment was received.",
  },

  partners: {
    title: "For Partners",

    desc1: 'N-change invites website owners and administrators to join our affiliate program. To do this, you need:',

    list1: {
      item1: "Register as a user on n-change.net",
      item2: "Place an affiliate link on your blog or website.",
      item3: "Invite your friends using a personal affiliate link, which is available in the client's account",
    },

    desc2: 'After you have given an affiliate link to a friend, each client who clicked on the n-change.net website and performed an operation using our service is considered a client attracted by you as a service partner. With each such operation made by attracted clients on the site n-change.net, the partner receives a profit as a percentage of the exchange amount. The percentage paid to the partner depends on the partner\'s level. The more referrals a partner has, the more profit the partner receives. The percentage is determined according to the following scheme:',

    list2: {
      item1: "0.5% of the exchange amount - after registration on the site",
      item2: "0.7% of the exchange amount - for 10 invited referrals who made the exchange",
      item3: "0.8% of the exchange amount - for 50 invited referrals who made the exchange",
      item4: '0.9% of the exchange amount - for 100 referrals who made the exchange',
      item5: '1% of the exchange amount - for 300 referrals who made the exchange',
    },

    desc3: 'If a client attracted by a partner performs an operation on the service without registering, he is still considered an attracted client as long as it is technically possible to unambiguously establish a connection between this client and the partner (determined through a cookie saved on the client side at the time of the first transition to the Service website from the Partner\'s website).',
    desc4: 'Our affiliate program is attractive in that with an increase in the number of clients attracted by you, your profit grows not only in quantitative, but also as a percentage of the volume of transactions made by attracted clients.',
    desc5: 'We also draw your attention to the fact that if the service commission has a negative value, we do not charge remuneration for the referral system.',
    desc6: 'We are glad to cooperate with various Internet resources. Advertising of the distribution of bonuses is not prohibited, however, we draw your attention to the fact that partners receive profit only for operations performed through our service, and not for transitions to the service website. The use of sending emails (known as SPAM) for the purpose of advertising our service is expressly prohibited. In the event of complaints from e-mail owners about such mailings, partner accounts will be deleted with a complete loss of earned funds.',
  },

  contact: {
    title: "Contact Information",

    left: {
      title: "Support",
      desc: 'If you have any questions, contact the online operator on the site or write to us by mail.',
      item1: {
        title: 'Mail support:',
      },
      item2: {
        title: 'Online chat:',
        desc: 'Open chat for support',
      },
      item3: {
        title: 'Operating time',
        desc: 'Around the clock',
      },
    },

    right: {
      title: 'Reviews',
      desc: "Customer reviews of the service on third-party sites, we are glad to your feedback:",
      link: 'Monitoring Best Change',
    }
  },

  footer: {
    copy: '© Instant currency exchange service N-change.net',
    rules: 'All rights reserved. Copyright © 2023',
  }
};
