export const CRYPTOCURRENCY_FORM = [
  {
    id: "bitcoin",
    symbol: "BTC",
    name: "Bitcoin",
    memo: false,
    wallet: "bc1qf2qx8m0pvpurg7cqm0563ew7w4nlr8kdlh9f6j",
    icon: require("../assets/img/cryptocurrency/btc.svg").default,
  },
  {
    id: "litecoin",
    symbol: "LTC",
    name: "Litecoin",
    memo: false,
    wallet: "ltc1qlrqfwualxc0u0nq4d5j4yu7eswj9e67fzwpf37",
    icon: require("../assets/img/cryptocurrency/ltc.svg").default,
  },
  {
    id: "ethereum",
    symbol: "ETH",
    name: "Ethereum",
    memo: false,
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/eth.svg").default,
  },
  {
    id: "ripple",
    symbol: "XRP",
    name: "Ripple",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/xrp.svg").default,
  },
  {
    id: "tron",
    symbol: "TRX",
    name: "TRON",
    memo: false,
    wallet: "TB7VxFGtMvVCGnSx4jVMwqp2EdZMZ4rsbF",
    icon: require("../assets/img/cryptocurrency/trx.svg").default,
  },
  {
    id: "tethererc",
    symbol: "USDT1",
    name: "Tether ERC20",
    memo: false,
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "tethertrc",
    symbol: "USDT2",
    name: "Tether TRC20",
    memo: false,
    wallet: "TB7VxFGtMvVCGnSx4jVMwqp2EdZMZ4rsbF",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "tetherbep",
    symbol: "USDT3",
    name: "Tether BEP20",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "solana",
    symbol: "SOL",
    name: "Solana",
    memo: false,
    wallet: "2vM72J7EVnWnfKZ3Ga6XoXhESB9UhYc5Cj5SQhtRcBvy",
    icon: require("../assets/img/cryptocurrency/sol.svg").default,
  },
  {
    id: "monero",
    symbol: "XMR",
    name: "Monero",
    memo: false,
    wallet: "0x8E87Cd57385e78365849095A2e6C9e76De6F036e",
    icon: require("../assets/img/cryptocurrency/xmr.svg").default,
  },
  {
    id: "polygon",
    symbol: "MATIC",
    name: "Polygon",
    memo: false,
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/matic.svg").default,
  },
  {
    id: "polkadot",
    symbol: "DOT",
    name: "Polkadot (DOT)",
    memo: false,
    wallet: "154zdS1rcvmtxTJFbEVp37NqnjLrRieQLzSX2QvD2kqciJ2n",
    icon: require("../assets/img/cryptocurrency/dot.svg").default,
  },
  {
    id: "binancecoin",
    symbol: "BNB",
    name: "BinanceCoin BEP20",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/bnb.svg").default,
  },
  {
    id: "stellar",
    symbol: "XLM",
    name: "Stellar",
    memo: false,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/xlm.svg").default,
  },
  {
    id: "eos",
    symbol: "EOS",
    name: "EOS (EOS)",
    memo: false,
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/eos.svg").default,
  },
];

export const CRYPTOCURRENCY_TO = [
  {
    id: "bitcoin",
    symbol: "BTC",
    name: "Bitcoin",
    memo: false,
    reserve: "34.8",
    wallet: "bc1qf2qx8m0pvpurg7cqm0563ew7w4nlr8kdlh9f6j",
    icon: require("../assets/img/cryptocurrency/btc.svg").default,
  },
  {
    id: "litecoin",
    symbol: "LTC",
    name: "Litecoin",
    memo: false,
    wallet: "ltc1qlrqfwualxc0u0nq4d5j4yu7eswj9e67fzwpf37",
    reserve: "2444.55",
    icon: require("../assets/img/cryptocurrency/ltc.svg").default,
  },
  {
    id: "ethereum",
    symbol: "ETH",
    name: "Ethereum",
    memo: false,
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    reserve: "1183.29 ",
    icon: require("../assets/img/cryptocurrency/eth.svg").default,
  },
  {
    id: "ripple",
    symbol: "XRP",
    name: "Ripple",
    memo: false,
    wallet: "",
    reserve: "172000.53",
    icon: require("../assets/img/cryptocurrency/xrp.svg").default,
  },
  {
    id: "tron",
    symbol: "TRX",
    name: "TRON",
    memo: false,
    wallet: "TB7VxFGtMvVCGnSx4jVMwqp2EdZMZ4rsbF",
    reserve: "6023136.25",
    icon: require("../assets/img/cryptocurrency/trx.svg").default,
  },
  {
    id: "tethererc",
    symbol: "USDT1",
    name: "Tether ERC20",
    memo: false,
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    reserve: "1480578.14",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "tethertrc",
    symbol: "USDT2",
    name: "Tether TRC20",
    memo: false,
    wallet: "TB7VxFGtMvVCGnSx4jVMwqp2EdZMZ4rsbF",
    reserve: "361558.72",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "tetherbep",
    symbol: "USDT3",
    name: "Tether BEP20",
    memo: false,
    wallet: "",
    reserve: "1488416.23",
    icon: require("../assets/img/cryptocurrency/usdt.svg").default,
  },
  {
    id: "solana",
    symbol: "SOL",
    name: "Solana",
    memo: false,
    wallet: "2vM72J7EVnWnfKZ3Ga6XoXhESB9UhYc5Cj5SQhtRcBvy",
    reserve: "100934.99",
    icon: require("../assets/img/cryptocurrency/sol.svg").default,
  },
  {
    id: "monero",
    symbol: "XMR",
    name: "Monero",
    memo: false,
    wallet: "0x8E87Cd57385e78365849095A2e6C9e76De6F036e",
    reserve: "33563.46",
    icon: require("../assets/img/cryptocurrency/xmr.svg").default,
  },
  {
    id: "polygon",
    symbol: "MATIC",
    name: "Polygon",
    memo: false,
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    reserve: "1101600",
    icon: require("../assets/img/cryptocurrency/matic.svg").default,
  },
  {
    id: "polkadot",
    symbol: "DOT",
    name: "Polkadot (DOT)",
    memo: false,
    wallet: "154zdS1rcvmtxTJFbEVp37NqnjLrRieQLzSX2QvD2kqciJ2n",
    reserve: "855604",
    icon: require("../assets/img/cryptocurrency/dot.svg").default,
  },
  {
    id: "binancecoin",
    symbol: "BNB",
    name: "BinanceCoin BEP20",
    memo: false,
    wallet: "",
    reserve: "148366.37",
    icon: require("../assets/img/cryptocurrency/bnb.svg").default,
  },
  {
    id: "stellar",
    symbol: "XLM",
    name: "Stellar",
    memo: false,
    wallet: "",
    reserve: "1530000",
    icon: require("../assets/img/cryptocurrency/xlm.svg").default,
  },
  {
    id: "eos",
    symbol: "EOS",
    name: "EOS (EOS)",
    memo: false,
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    reserve: "1381100",
    icon: require("../assets/img/cryptocurrency/eos.svg").default,
  },
];
