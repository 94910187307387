import { Modal, Radio, Tabs } from "antd";
import React from "react";
import { CRYPTOCURRENCY_FORM } from "../../constants/CryptocurrencyArray";

const ModalCurrencyFrom = ({ modalFromOpen, handleCancel, setState }) => {
  return (
    <Modal
      title={"Выберите валюту которую отдаете"}
      open={modalFromOpen}
      onCancel={handleCancel}
      width={670}
      footer={null}
    >
      <div className="tabs">
        <div className="tabs__content">
          <div className="nav_tabs">
            <span className="nav_tabs__text">Фильтр валют:</span>
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  key: "1",
                  label: "Все",
                  children: (
                    <Radio.Group
                      defaultValue={"BTC"}
                      onChange={(e) => {
                        const selectedCurrency = CRYPTOCURRENCY_FORM.find(
                          (item) => item.symbol === e.target.value
                        );
                        setState((prevState) => ({
                          ...prevState,
                          currencyFrom: e.target.value,
                          infoCoinFrom: selectedCurrency,
                        }));
                      }}
                    >
                      {CRYPTOCURRENCY_FORM.map((item, index) => (
                        <Radio.Button value={item.symbol} key={index}>
                          <div className="logo">
                            <img src={item.icon} alt="" />
                          </div>
                          <s>{item.name}</s>
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  ),
                },
                {
                  key: "2",
                  label: "RUB",
                  children: "",
                },
                {
                  key: "3",
                  label: "USD",
                  children: "",
                },
                {
                  key: "4",
                  label: "Coin",
                  children: (
                    <Radio.Group
                      defaultValue={"BTC"}
                      onChange={(e) => {
                        const selectedCurrency = CRYPTOCURRENCY_FORM.find(
                          (item) => item.symbol === e.target.value
                        );
                        setState((prevState) => ({
                          ...prevState,
                          currencyFrom: e.target.value,
                          infoCoinFrom: selectedCurrency,
                        }));
                      }}
                    >
                      {CRYPTOCURRENCY_FORM.map((item, index) => (
                        <Radio.Button value={item.symbol} key={index}>
                          <div className="logo">
                            <img src={item.icon} alt="" />
                          </div>
                          <s>{item.name}</s>
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCurrencyFrom;
