import React from "react";
import NavBar from "../components/NavBar";
import Advantage from "../components/Home/Advantage";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Successfully = () => {
  const { t } = useTranslation();
  const basePath = "exchange.finish.";

  const handleDeleteOrder = () => {
    localStorage.removeItem("exchangeFormData");
    window.location.href = "/";
  };

  return (
    <main>
      <NavBar />
      <div className="order">
        <section>
          <div className="main">
            <div className="box">
              <h1 className="v2">{t(`${basePath}title`)}</h1>
              <p className="v2">
                {t(`${basePath}desc1`)}
                <br /> <br />
              </p>
              <p className="v2">
                {t(`${basePath}desc2`)}
                <br />
                {t(`${basePath}desc3`)} <br />
                <br />
              </p>
              <p className="v2">
                {t(`${basePath}desc4`)} <br />
                {t(`${basePath}desc5`)}
              </p>

              <div className="back">
                <button onClick={handleDeleteOrder}>
                  {t(`${basePath}btn`)}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Advantage />
      <Footer />
    </main>
  );
};

export default Successfully;
