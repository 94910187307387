import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useTranslation } from "react-i18next";

const Rules = () => {
  const { t } = useTranslation();
  const basePath = "rules.";

  return (
    <main>
      <NavBar className={"d-none"} />
      <div className="order v2">
        <section>
          <div className="main v2">
            <h1 className="title">{t(`${basePath}title`)}</h1>
            <div className="box">
              <h4>{t(`${basePath}item1.title`)}</h4>
              <p>{t(`${basePath}item1.desc1`)}</p>

              <h4>{t(`${basePath}item2.title`)}</h4>
              <p>{t(`${basePath}item2.desc1`)}</p>
              <p>{t(`${basePath}item2.desc2`)}</p>
              <p>{t(`${basePath}item2.desc3`)}</p>
              <p>{t(`${basePath}item2.desc4`)}</p>
              <p>{t(`${basePath}item2.desc5`)}</p>
              <p>{t(`${basePath}item2.desc6`)}</p>

              <h4>{t(`${basePath}item3.title`)}</h4>
              <p>{t(`${basePath}item3.desc1`)}</p>

              <h4>{t(`${basePath}item4.title`)}</h4>
              <p>{t(`${basePath}item4.desc1`)}</p>

              <h4>{t(`${basePath}item5.title`)}</h4>
              <p>{t(`${basePath}item5.desc1`)}</p>
              <p>{t(`${basePath}item5.desc2`)}</p>
              <p>{t(`${basePath}item5.desc3`)}</p>
              <p>{t(`${basePath}item5.desc4`)}</p>
              <p>{t(`${basePath}item5.desc5`)}</p>
              <p>{t(`${basePath}item5.desc6`)}</p>
              <p>{t(`${basePath}item5.desc7`)}</p>
              <p>{t(`${basePath}item5.desc8`)}</p>
              <p>{t(`${basePath}item5.desc9`)}</p>
              <p>{t(`${basePath}item5.desc10`)}</p>
              <p>{t(`${basePath}item5.desc11`)}</p>
              <p>{t(`${basePath}item5.desc12`)}</p>
              <p>{t(`${basePath}item5.desc13`)}</p>
              <p>{t(`${basePath}item5.desc14`)}</p>
              <p>{t(`${basePath}item5.desc15`)}</p>
              <p>{t(`${basePath}item5.desc16`)}</p>
              <p>{t(`${basePath}item5.desc17`)}</p>
              <p>{t(`${basePath}item5.desc18`)}</p>
              <p>{t(`${basePath}item5.desc19`)}</p>
              <p>{t(`${basePath}item5.desc20`)}</p>
              <p>{t(`${basePath}item5.desc21`)}</p>
              <p>{t(`${basePath}item5.desc22`)}</p>
              <p>{t(`${basePath}item5.desc23`)}</p>
              <p>{t(`${basePath}item5.desc24`)}</p>
              <p>{t(`${basePath}item5.desc25`)}</p>
              <p>{t(`${basePath}item5.desc26`)}</p>
              <p>{t(`${basePath}item5.desc27`)}</p>
              <p>{t(`${basePath}item5.desc28`)}</p>
              <p>{t(`${basePath}item5.desc29`)}</p>

              <h4>{t(`${basePath}item6.title`)}</h4>
              <p>{t(`${basePath}item6.desc1`)}</p>

              <h4>{t(`${basePath}item7.title`)}</h4>
              <p>{t(`${basePath}item7.desc1`)}</p>

              <h4>{t(`${basePath}item8.title`)}</h4>
              <p>{t(`${basePath}item8.desc1`)}</p>

              <h4>{t(`${basePath}item10.title`)}</h4>
              <p>{t(`${basePath}item10.desc1`)}</p>

              <h4>{t(`${basePath}item11.title`)}</h4>
              <p>{t(`${basePath}item11.desc1`)}</p>
              <p>{t(`${basePath}item11.desc2`)}</p>
              <p>{t(`${basePath}item11.desc3`)}</p>
              <p>{t(`${basePath}item11.desc4`)}</p>
              <p>{t(`${basePath}item11.desc5`)}</p>

              <h4>{t(`${basePath}item12.title`)}</h4>
              <p>{t(`${basePath}item12.desc1`)}</p>
              <p>{t(`${basePath}item12.desc2`)}</p>

              <h4>{t(`${basePath}item13.title`)}</h4>
              <p>{t(`${basePath}item13.desc1`)}</p>
              <p>{t(`${basePath}item13.desc2`)}</p>
              <p>{t(`${basePath}item13.desc3`)}</p>
              <p>{t(`${basePath}item13.desc4`)}</p>
              <p>{t(`${basePath}item13.desc5`)}</p>
              <p>{t(`${basePath}item13.desc6`)}</p>
              <p>{t(`${basePath}item13.desc7`)}</p>
              <p>{t(`${basePath}item13.desc8`)}</p>
              <p>{t(`${basePath}item13.desc9`)}</p>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </main>
  );
};

export default Rules;
