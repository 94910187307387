import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const basePath = "footer.";

  return (
    <div className={`footer`}>
      <section>
        {t(`${basePath}copy`)}
        <br /> {t(`${basePath}rules`)}
        <br />
        <a href="https://www.bestchange.ru/" rel="noreferrer" target="_blank">
          <img
            src="https://www.bestchange.ru/images/logo-orig.svg"
            title="Обмен QIWI, Bitcoin, Tether, AdvCash"
            alt="Мониторинг обменных пунктов BestChange"
            width="88"
            height="31"
            border="0"
          />
        </a>
      </section>
    </div>
  );
};

export default Footer;
