import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useTranslation } from "react-i18next";

const AML = () => {
  const { t } = useTranslation();
  const basePath = "aml.";

  return (
    <main>
      <NavBar className={"d-none"} />
      <div className="order v2">
        <section>
          <div className="main v2">
            <h1 className="title">{t(`${basePath}title`)}</h1>
            <div className="box">
              <p className="m-1">{t(`${basePath}desc1`)}</p>
              <p className="m-1">{t(`${basePath}desc2`)}</p>
              <p className="m-1">{t(`${basePath}desc3`)}</p>
              <p className="m-1">{t(`${basePath}desc4`)}</p>
              <p className="m-1">
                {t(`${basePath}desc5`)}
                <br />
                Illegal Service <br />
                Mixing Service <br />
                Fraudulent Exchange <br />
                Darknet Marketplace Darknet Service <br />
                Ransom <br />
                Scam <br />
                Stolen Coins <br />
                Terrorism Financing <br />
                Sanctions <br />
                Illicit Actor/Organization <br />
                High-Risk Jurisdiction <br />
                Gambling <br />
                Fraud Shop <br />
                Enforcement action <br />
                Child Exploitation
              </p>
              <p className="m-1">{t(`${basePath}desc6`)}</p>
              <p className="m-1">{t(`${basePath}desc7`)}</p>
              <p className="m-1">{t(`${basePath}desc8`)}</p>
              <p className="m-1">{t(`${basePath}desc9`)}</p>
              <p className="m-1">{t(`${basePath}desc10`)}</p>
              <p className="m-1">{t(`${basePath}desc11`)}</p>
              <p className="m-1">{t(`${basePath}desc12`)}</p>
              <p className="m-1">{t(`${basePath}desc13`)}</p>
              <p className="m-1">{t(`${basePath}desc14`)}</p>
              <p className="m-1">{t(`${basePath}desc15`)}</p>
              <p className="m-1">{t(`${basePath}desc16`)}</p>
              <p className="m-1">{t(`${basePath}desc17`)}</p>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </main>
  );
};

export default AML;
