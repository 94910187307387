import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import dayjs from "dayjs";
import emailjs from "emailjs-com";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import Advantage from "../components/Home/Advantage";

const Order = () => {
  const { t } = useTranslation();
  const basePath = "exchange.order.";

  const [userData, setUserData] = useState(null);

  const getUserDataFromLocalStorage = () => {
    const storedUserData = localStorage.getItem("exchangeFormData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  };

  useEffect(() => {
    getUserDataFromLocalStorage();
  }, []);

  const getActualSymbol = (symbol) => {
    if (symbol && symbol.startsWith("USDT")) {
      return "USDT";
    }
    return symbol || "";
  };

  const sendEmail = async () => {
    const serviceId = "service_e2kb56b";
    const templateId = "template_k73r1gn";
    const userId = "zWUGrm_-1m3kIm23w";

    try {
      const templateParams = {
        amount: `${userData?.amount} ${userData?.infoCoinFrom?.name} - ${userData?.result} ${userData?.infoCoinTo?.name}`,
        submitTime: dayjs(userData?.submitTime).format("DD.MM.YYYY / HH:ss"),
        wallet: userData?.wallet,
        email: userData?.email,
      };

      const response = await emailjs.send(
        serviceId,
        templateId,
        templateParams,
        userId
      );
      console.log("Email sent successfully!", response);
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  };

  const handleDeleteOrder = () => {
    localStorage.removeItem("exchangeFormData");
    window.location.href = "/";
  };

  const handlePayOrder = async () => {
    const updatedUserData = { ...userData, status: "Pending" };
    localStorage.setItem("exchangeFormData", JSON.stringify(updatedUserData));

    try {
      await sendEmail();
      window.location.href = "/successfully/";
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <main>
      <NavBar />
      <div className="order">
        <section>
          <div className="main">
            <div className="box">
              <h1>{t(`${basePath}title`)}</h1>
              <p>{t(`${basePath}desc`)}</p>

              <div className="list">
                <div className="title">
                  <div className="number">1</div>
                  <span>{t(`${basePath}step.step1`)}</span>
                </div>
                <div className="ticket">
                  <div className="cell">
                    <div className="cell__title">Отдаю:</div>
                    <div className="cell__data">
                      <img src={userData?.infoCoinFrom?.icon} alt="" />
                      {userData?.infoCoinFrom?.name}
                    </div>
                  </div>

                  <div className="cell">
                    <div className="cell__title">Сумма:</div>
                    <div className="cell__data">
                      {userData?.amount}{" "}
                      {getActualSymbol(userData?.infoCoinFrom?.symbol)}
                    </div>
                  </div>

                  <div className="cell">
                    <div className="cell__title">Получаю:</div>
                    <div className="cell__data">
                      <img src={userData?.infoCoinTo?.icon} alt="" />
                      {userData?.infoCoinTo?.name}
                    </div>
                  </div>

                  <div className="cell">
                    <div className="cell__title">Сумма:</div>
                    <div className="cell__data">
                      {userData?.result}{" "}
                      {getActualSymbol(userData?.infoCoinTo?.symbol)}
                    </div>
                  </div>

                  <div className="cell">
                    <div className="cell__title">Кошелек:</div>
                    <div className="cell__data">{userData?.wallet}</div>
                  </div>
                </div>
              </div>

              <div className="list">
                <div className="title">
                  <div className="number">2</div>
                  <span>{t(`${basePath}step.step2`)}</span>
                </div>

                <div className="ticket">
                  <div className="cell v2">
                    <div className="cell__title">
                      {t(`${basePath}step.amount`)}
                    </div>
                    <div className="cell__data">
                      <span>{userData?.amount}</span>{" "}
                      {getActualSymbol(userData?.infoCoinFrom?.symbol)}
                    </div>
                    <div className="cell__copy">
                      <button>{t(`${basePath}step.copy`)}</button>
                    </div>
                  </div>

                  <div className="cell v2">
                    <div className="cell__title">
                      {t(`${basePath}step.wallet`)}
                    </div>
                    <div className="cell__data">
                      <span>{userData?.infoCoinTo?.wallet}</span>
                    </div>
                    <div className="cell__copy">
                      <button>{t(`${basePath}step.copy`)}</button>
                    </div>
                  </div>

                  <div className="cell v2">
                    <div className="cell__title">
                      {t(`${basePath}step.purpose`)}
                    </div>
                    <div className="cell__data">
                      <span>{t(`${basePath}step.purposeName`)}</span>
                    </div>
                    <div className="cell__copy">
                      <button>{t(`${basePath}step.copy`)}</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="list">
                <div className="title">
                  <div className="number">3</div>
                  <span>{t(`${basePath}step.step3`)}</span>
                </div>
              </div>

              <div className="listButton">
                <button className="paid" onClick={handlePayOrder}>
                  {t(`${basePath}step.btnPay`)}
                </button>
                <button className="cancel" onClick={handleDeleteOrder}>
                  {t(`${basePath}step.btnCancel`)}
                </button>
              </div>

              {/* {userData?.memo && (
                <div className="order-item">
                  <div className="title">Message:</div>
                  <div className="text">{userData?.memo}</div>
                </div>
              )} */}
            </div>
          </div>
        </section>
      </div>

      <Advantage />
      <Footer />
    </main>
  );
};

export default Order;
