import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Components List
import Home from "../pages/Home";
import Order from "../pages/Order";
import Successfully from "../pages/Successfully";
import Rules from "../pages/Rules";
import AML from "../pages/AML";
import Partners from "../pages/Partners";
import Contact from "../pages/Contact";

function Routes() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/order/" component={Order} />
          <Route path="/successfully/" component={Successfully} />
          <Route path="/rules/" component={Rules} />
          <Route path="/aml/" component={AML} />
          <Route path="/partners/" component={Partners} />
          <Route path="/contact/" component={Contact} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routes;
