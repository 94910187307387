import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const basePath = "contact.";

  return (
    <main>
      <NavBar className={"d-none"} />
      <div className="order v2">
        <section>
          <div className="main v2">
            <h1 className="title">{t(`${basePath}title`)}</h1>
            <div className="box">
              <div className="contact__block">
                <div className="cell">
                  <h3>{t(`${basePath}left.title`)}</h3>
                  <p className="mb-20">{t(`${basePath}left.desc`)}</p>
                  <div className="item">
                    <div className="icon">
                      <span></span>
                    </div>
                    <div className="desc">
                      <span>{t(`${basePath}left.item1.title`)}</span>
                      <b>support@n-change.net</b>
                    </div>
                  </div>

                  <div className="item">
                    <div className="icon">
                      <span></span>
                    </div>
                    <div className="desc">
                      <span>{t(`${basePath}left.item2.title`)}</span>
                      <a href="#">{t(`${basePath}left.item2.desc`)}</a>
                    </div>
                  </div>

                  <div className="item">
                    <div className="icon">
                      <span></span>
                    </div>
                    <div className="desc">
                      <span>{t(`${basePath}left.item3.title`)}</span>
                      <s>{t(`${basePath}left.item3.desc`)}</s>
                    </div>
                  </div>
                </div>
                <div className="cell">
                  <h3>{t(`${basePath}right.title`)}</h3>
                  <p>{t(`${basePath}right.desc`)}</p>
                  <a
                    href="https://www.bestchange.ru/n-change-exchanger.html"
                    className="best"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span></span> {t(`${basePath}right.link`)}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </main>
  );
};

export default Contact;
